export default () => {
  const dateFilter = payload => {
    const { period, number_of_period, start_date, end_date } = payload
    var d = new Date()
    var year = d.getFullYear()
    var month = d.getMonth()
    var day = d.getDate()
    var hour = d.getHours()
    var minutes = d.getMinutes()
    if (period === 'Today') {
      var num_of_days = new Date(year, month, day, 0, minutes).getTime()
    } else if (period === 'All time') {
      num_of_days = new Date(0, 0, 0, 0, 0).getTime()
    } else if (period === 'Days') {
      num_of_days = new Date(year, month, day - number_of_period, hour, minutes).getTime()
    } else if (period === 'Months') {
      num_of_days = new Date(year, month - number_of_period, day, hour, minutes).getTime()
    }

    return { start_date, end_date, num_of_days }
  }
  return { dateFilter }
}
