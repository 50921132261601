import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/HomeView.vue'
// import Login from '../views/LoginPage.vue'
// import ForgotPassword from '../views/ForgotPassword.vue'
// import Users from '../views/UsersView.vue'
// import Referrals from '../views/ReferralsView.vue'
// import NotActive from '../views/NotActive.vue'
// import Admins from '../views/AdminsView.vue'
// import WaitingRoom from '../views/WaitingRoom.vue'
// import ComeBackPage from '../views/ComeBackPage.vue'
// import Businesses from '../views/BusinessesView.vue'
// import NotFoundComponent from '../views/NotFoundComponent.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/LoginPage.vue')
  },
  {
    path: '/forgot_password',
    name: 'ForgotPassword',
    component: () => import('../views/ForgotPassword.vue')
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('../views/UsersView.vue')
  },
  {
    path: '/businesses/:type_id',
    name: 'Businesses',
    props: true,
    component: () => import('../views/BusinessesView.vue')
  },
  {
    path: '/referrals',
    name: 'Referrals',
    component: () => import('../views/ReferralsView.vue')
  },
  {
    path: '/not_active',
    name: 'NotActive',
    component: () => import('../views/NotActive.vue')
  },
  {
    path: '/admins',
    name: 'Admins',
    component: () => import('../views/AdminsView.vue')
  },
  {
    path: '/welcome_back',
    name: 'ComeBackPage',
    component: () => import('../views/ComeBackPage.vue')
  },
  {
    path: '/waiting_room',
    name: 'WaitingRoom',
    component: () => import('../views/WaitingRoom.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  { path: '*', component: () => import('../views/NotFoundComponent.vue') }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
