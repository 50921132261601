import { getDocs, getDoc } from 'firebase/firestore'
import useDateFilter from '@/assets/lib/date_filter'
import useFirebaseQuery from '@/assets/lib/firebase_query'

export const namespaced = true

export const state = {
  not_active_businesses: [],
  loading: false
}

export const mutations = {
  SET_NOT_ACTIVE_BUSINESS(state, payload) {
    state.not_active_businesses = payload
  }
}

export const actions = {
  // async getNotActiveBusinesses({ dispatch }, payload) {
  //   const { period, number_of_period } = payload

  //   var d = new Date()
  //   var year = d.getFullYear()
  //   var month = d.getMonth()
  //   var day = d.getDate()
  //   var hour = d.getHours()
  //   var minutes = d.getMinutes()
  //   if (period === 'Days') {
  //     var num_of_days = new Date(year, month, day - number_of_period, hour, minutes).getTime()
  //   } else if (period === 'Months') {
  //     num_of_days = new Date(year, month - number_of_period, day, hour, minutes).getTime()
  //   }

  //   await dispatch('businesses', { num_of_days: num_of_days })
  // },
  async getNotActiveBusinesses({ commit }, payload) {
    const { start_date, end_date, num_of_days } = useDateFilter().dateFilter(payload)

    let businesses
    if (payload.period !== 'Between Dates') {
      businesses = useFirebaseQuery().simpleQuery('businesses', 'last_active', num_of_days)
    } else {
      businesses = useFirebaseQuery().complexQuery('businesses', 'last_active', start_date, end_date)
    }
    // const businesses = query(
    //   collection(fb.db, 'businesses'),
    //   where('last_active', '<', num_of_days),
    //   orderBy('last_active', 'desc')
    // )
    const querySnapshot = await getDocs(businesses)
    commit('SET_NOT_ACTIVE_BUSINESS', [])
    const notActiveArray = []
    if (!querySnapshot.empty) {
      querySnapshot.forEach(async notActiveDoc => {
        const notActiveObject = notActiveDoc.data()
        notActiveObject.business_id = notActiveDoc.id

        // const docRef = doc(fb.db, 'users', notActiveObject.owner_id)
        const docRef = useFirebaseQuery().simpleGetDoc('users', notActiveObject.owner_id)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
          notActiveObject.owner = docSnap.data()
        }
        notActiveArray.push(notActiveObject)
      })
      commit('SET_NOT_ACTIVE_BUSINESS', notActiveArray)
    } else {
      commit('SET_NOT_ACTIVE_BUSINESS', notActiveArray)
    }
  }
}

export const getters = {}
