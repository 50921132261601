import fb from '@/services/firebase'
import { collection, orderBy, query, where, getDocs } from 'firebase/firestore'

export const namespaced = true

export const state = {
  referrals: [],
  loading: false,
  start_date: 0,
  end_date: 0
}

export const mutations = {
  SET_REFERRALS(state, payload) {
    state.referrals = payload
  },
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_FILTER_DATE(state, payload) {
    state.start_date = payload.start_date
    state.end_date = payload.end_date
  }
}

export const actions = {
  dateFilter({ commit }, payload) {
    let start_date = 0
    let end_date = new Date().getTime()
    const { period, number_of_period } = payload
    var d = new Date()
    var year = d.getFullYear()
    var month = d.getMonth()
    var day = d.getDate()
    var hour = d.getHours()
    var minutes = d.getMinutes()
    if (period === 'Today') {
      start_date = new Date(year, month, day, 0, minutes).getTime()
    } else if (period === 'All time') {
      start_date = new Date(0, 0, 0, 0, 0).getTime()
    } else if (period === 'Days') {
      start_date = new Date(year, month, day - number_of_period, hour, minutes).getTime()
    } else if (period === 'Months') {
      start_date = new Date(year, month - number_of_period, day, hour, minutes).getTime()
    } else if (period === 'Between Dates') {
      start_date = payload.start_date
      end_date = payload.end_date
    }
    commit('SET_FILTER_DATE', { start_date, end_date })
  },

  async Referrals({ commit }) {
    commit('SET_REFERRALS', [])
    const userDocs = query(collection(fb.db, 'users'))
    const querySnapshot = await getDocs(userDocs)
    if (!querySnapshot.empty) {
      querySnapshot.forEach(async userDoc => {
        const referrals = query(
          collection(fb.db, 'users', userDoc.id, 'referrals'),
          where('created_at', '>=', state.start_date),
          where('created_at', '<=', state.end_date),
          orderBy('created_at', 'desc')
        )
        const querySnapshot = await getDocs(referrals)
        const container = []
        if (!querySnapshot.empty) {
          querySnapshot.forEach(doc => {
            let details = doc.data()
            details.id = doc.id
            details.first_name = userDoc.data().first_name
            details.last_name = userDoc.data().last_name
            details.user_id = userDoc.id
            container.push(details)
          })
          commit('SET_REFERRALS', container)
        }
      })
    }

    // onSnapshot(query(collection(fb.db, 'users')), userDocs => {
    //   userDocs.forEach(userDoc => {
    //     let users_id = userDoc.id
    //     onSnapshot(
    //       query(
    //         collection(fb.db, 'users', users_id, 'referrals'),
    //         where('created_at', '>=', state.start_date),
    //         where('created_at', '<=', state.end_date),
    //         orderBy('created_at', 'desc')
    //       ),
    //       referralsDocs => {
    //         referralsDocs.forEach(doc => {
    //           let details = doc.data()
    //           details.id = doc.id
    //           details.first_name = userDoc.data().first_name
    //           details.last_name = userDoc.data().last_name
    //           details.user_id = users_id
    //           container.push(details)
    //         })
    //         commit('SET_REFERRALS', container)
    //       }
    //     )
    //   })
    // })
  }
  // complexDateRangeForReferrals({ commit }, payload) {
  //   const { start_date, end_date } = payload
  //   commit('SET_REFERRALS', [])
  //   var container = []
  //   onSnapshot(query(collection(fb.db, 'users')), userDocs => {
  //     userDocs.forEach(userDoc => {
  //       let users_id = userDoc.id
  //       onSnapshot(
  //         query(
  //           collection(fb.db, 'users', users_id, 'referrals'),
  //           where('created_at', '>=', start_date),
  //           where('created_at', '<=', end_date),
  //           orderBy('created_at', 'desc')
  //         ),
  //         referralsDocs => {
  //           referralsDocs.forEach(doc => {
  //             let details = doc.data()
  //             details.id = doc.id
  //             details.first_name = userDoc.data().first_name
  //             details.last_name = userDoc.data().last_name
  //             details.user_id = users_id
  //             container.push(details)
  //           })
  //           commit('SET_REFERRALS', container)
  //         }
  //       )
  //     })
  //   })
  // }
}

export const getters = {}
