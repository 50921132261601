import fb from '@/services/firebase'
import { doc, getDoc } from 'firebase/firestore'

export const namespaced = true

export const state = {
  settings: [],
  users: [],
  settings_loading: false
}

export const mutations = {
  SET_SETTINGS_LOADING(state, payload) {
    state.settings_loading = payload
  },
  SET_SETTINGS(state, payload) {
    state.settings = payload
  },
  SET_USERS(state, payload) {
    state.users = payload
  }
}

export const actions = {
  async getAdminUsers({ commit }, payload) {
    commit('SET_SETTINGS_LOADING', true)
    // onSnapshot(doc(fb.db, 'settings', payload), settingsDoc => {
    //   if (settingsDoc.exists) {
    //     commit('SET_SETTINGS', settingsDoc.data())
    //   }
    // })
    const docRef = doc(fb.db, 'settings', payload)
    const settingsDoc = await getDoc(docRef)
    if (settingsDoc.exists()) {
      commit('SET_SETTINGS', settingsDoc.data())
    }
  },
  async getUsers({ commit }, payload) {
    const container = []
    payload.forEach(async element => {
      const docRef = doc(fb.db, 'users', element.user)
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        const details = docSnap.data()
        details.id = docRef.id
        container.push(details)
      }
    })
    commit('SET_USERS', container)
  }
}
