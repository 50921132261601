import Swal from 'sweetalert2'

export default () => {
  function getBrowserType() {
    var ua = navigator.userAgent,
      tem,
      M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || []
      return 'IE ' + (tem[1] || '')
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/)
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera')
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1])
    return M.join(' ')
  }

  function getPlatform(payload) {
    var platform = navigator.platform
    return platform.search(payload)
  }

  var browser = getBrowserType()

  function searchBrowser(payload) {
    return browser.search(payload)
  }

  if (searchBrowser('Safari') != -1) {
    Swal.fire(
      'Info',
      'You are currently using ' + browser + '<br/><br/> Cleva works at its best performance on Google Chrome',
      'info'
    )
  }
  if (searchBrowser('Safari') != -1 || searchBrowser('Chrome') != -1 || searchBrowser('Firefox') != -1) {
    //
  } else {
    Swal.fire({
      position: 'center',
      icon: 'info',
      text:
        'You are currently using ' +
        browser +
        '. Cleva works at its best performance on Google Chrome, it does not work with ' +
        browser,
      showConfirmButton: false,
      timer: 8000
    })
    this.$router.push({
      name: 'Home'
    })
  }

  return { searchBrowser, getPlatform }
}
