var firebase = {}
firebase.api_key = 'AIzaSyBv-iMyFkUuEvsVVAl096fycKY1XcfhjzI'
firebase.auth_domain = 'cleva-2fbfc.firebaseapp.com'
firebase.project_id = 'cleva-2fbfc'
firebase.storage_bucket = 'cleva-2fbfc.appspot.com'
// AUTH_DOMAIN='cleva-2fbfc.firebaseapp.com',
// PROJECT_ID='cleva-2fbfc',
// STORAGE_BUCKET='cleva-2fbfc.appspot.com',
// MESSAGING_SENDER_ID='826491315730',
// APP_ID='1:826491315730:web:302dcf5eb44659036a9acb',
// MEASUREMENT_ID='G-5PS24R7BJ5'

module.exports = firebase
