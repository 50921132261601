import router from '../../router'
import fb from '@/services/firebase'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { doc, onSnapshot } from 'firebase/firestore'

export const namespaced = true

export const state = {
  loading: false
}

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload
  }
}

export const actions = {
  isLoggedIn({ commit }) {
    commit('SET_LOADING', true)

    const auth = getAuth(fb.app)
    onAuthStateChanged(auth, user => {
      if (!user) {
        if (router.currentRoute.name != 'Login') {
          router.push({
            name: 'Login'
          })
        }
      } else {
        onSnapshot(doc(fb.db, 'admins', user.uid), userDoc => {
          if (userDoc.exists()) {
            if (router.currentRoute.name == 'WaitingRoom' || router.currentRoute.name == 'Login') {
              router.push({
                name: 'Home'
              })
            }
          } else {
            if (router.currentRoute.name != 'WaitingRoom') {
              router.push({
                name: 'WaitingRoom'
              })
            }
          }
        })
      }
    })
  },
  forLoginPage({ commit }) {
    commit('SET_LOADING', true)
    const auth = getAuth(fb.app)
    onAuthStateChanged(auth, user => {
      if (user) {
        if (router.currentRoute.name != 'Home') {
          router.push({
            name: 'Home'
          })
        }
      }
    })
  },
  forRegisterPage({ commit }) {
    commit('SET_LOADING', true)
    const auth = getAuth(fb.app)
    onAuthStateChanged(auth, user => {
      if (!user) {
        if (router.currentRoute.name != 'Register') {
          router.push({
            name: 'Register'
          })
        }
      } else {
        //
      }
    })
  }
}

export const getters = {}
