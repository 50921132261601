import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@/assets/css/tailwind.css'
import fb from '@/services/firebase'
import VCalendar from 'v-calendar'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
let app
const auth = getAuth(fb.app)
onAuthStateChanged(auth, user => {
  if (!app) {
    if (user) {
      store.dispatch('setUser', user)
    }
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
    Vue.use(VCalendar, {
      componentPrefix: 'vc'
    })
  }
})
Vue.config.productionTip = false
