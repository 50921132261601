import fb from '@/services/firebase.js'
import { collection, query, where, onSnapshot, doc, updateDoc, setDoc, getDocs, deleteDoc } from 'firebase/firestore'

export const namespaced = true

export const state = {
  admins: [],
  pending_admins: [],
  loading: false
}

export const mutations = {
  SET_ADMINS(state, payload) {
    state.admins = payload
  },
  SET_PENDING_ADMINS(state, payload) {
    state.pending_admins = payload
  },
  SET_LOADING(state, payload) {
    state.loading = payload
  }
}

export const actions = {
  admins({ commit }) {
    commit('SET_ADMINS', [])
    const adminsQuery = query(collection(fb.db, 'admins'), where('approved', '==', true))
    onSnapshot(adminsQuery, docs => {
      var value = []
      docs.forEach(doc => {
        var holder = doc.data()
        holder.id = doc.id
        value.push(holder)
      })
      commit('SET_ADMINS', value)
    })
  },
  disable_admin({ dispatch }, payload) {
    const uid = payload.id
    delete payload.id
    delete payload.role
    fb.db
      .collection('admins_notapproved')
      .doc(uid)
      .set(payload)
      .then(() => {
        dispatch('deleteFromAdmins', uid)
      })
  },
  async deleteFromAdmins({ commit }, payload) {
    commit('SET_LOADING', true)
    var finished = await deleteDoc(doc(fb.db, 'admins', payload))
    if (finished === undefined) {
      commit('SET_LOADING', false)
    }
  },
  async approve_admin({ commit }, payload) {
    commit('SET_LOADING', true)
    const uid = payload.id
    delete payload.id

    var finished = await setDoc(doc(fb.db, 'admins', uid), payload)
    if (finished === undefined) {
      commit('SET_LOADING', false)
    }
  },
  async update_admin({ commit }, payload) {
    commit('SET_LOADING', true)
    var uid = payload.id
    delete payload.id

    const updateAdmin = doc(fb.db, 'admins', uid)

    await updateDoc(updateAdmin, { role: payload.role })
  },
  async dashboardAdmin({ commit }) {
    const admin = query(collection(fb.db, 'dashboard_summary', 'admins', 'breakdown'))
    const querySnapshot = await getDocs(admin)
    const container = []
    if (!querySnapshot.empty) {
      querySnapshot.forEach(snapDoc => {
        const details = snapDoc.data()
        details.id = snapDoc.id
        container.push(details)
      })
      commit('SET_ADMINS', container)
    } else {
      commit('SET_ADMINS', container)
    }
  }
}

export const getters = {}
