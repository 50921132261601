import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAnalytics } from 'firebase/analytics'
import config from './../../config'
import browserType from '../assets/lib/browser_type'

if (process.env.NODE_ENV === 'production') {
  var firebaseConfig = {
    apiKey: config.api_key,
    authDomain: config.auth_domain,
    projectId: config.project_id,
    storageBucket: config.storage_bucket,
    messagingSenderId: '826491315730',
    appId: '1:826491315730:web:302dcf5eb44659036a9acb',
    measurementId: 'G-5PS24R7BJ5'
  }
} else {
  firebaseConfig = {
    apiKey: 'AIzaSyDZkKPgT1K4CBRI_KLSEjTMe8ZULFnFJ7E',
    authDomain: 'cleva-test.firebaseapp.com',
    projectId: 'cleva-test',
    storageBucket: 'cleva-test.appspot.com',
    messagingSenderId: '672222440869',
    appId: '1:672222440869:web:c6cfbff71a72fe0a9d8c4c',
    measurementId: 'G-WHK3CMDRGX'
  }
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
if (browserType().getPlatform('Mac') == -1) {
  getAnalytics(app)
}
const db = getFirestore(app)

export default {
  app,
  db
}
