<template>
  <div id="app">
    <router-view />
    <div class="absolute fade inset-0 z-50" v-if="new_app_version" @click="updateVersion">
      <div class="flex items-center justify-center h-full">
        <div class="cursor-pointer">
          <div class="bg-primary animate-pulse shadow-md hover:shadow-xl px-4 py-2 text-white text-sm rounded-md">
            New Version Available! Click to update
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    new_app_version() {
      return this.$store.state.new_app_version
    }
  },
  methods: {
    updateVersion() {
      this.$store.dispatch('addAppVersionToLocalStorage')
    }
  },
  mounted() {
    this.$store.dispatch('appVersion')
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.fade {
  background-color: #b8c4ba99;
}
</style>
