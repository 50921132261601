import fb from '@/services/firebase'
import { collection, doc, limit, orderBy, query, startAfter, where } from 'firebase/firestore'

export default () => {
  const simpleQuery = (table_name, search_name, num_of_days) => {
    const build_query = query(
      collection(fb.db, table_name),
      where(search_name, '>=', num_of_days),
      orderBy(search_name, 'desc')
    )

    return build_query
  }
  const simpleQueryWithOrderAndLimit = (table_name, search_name, num_of_days, no_per_page) => {
    const build_query = query(
      collection(fb.db, table_name),
      where(search_name, '>=', num_of_days),
      orderBy(search_name, 'desc'),
      limit(no_per_page)
    )

    return build_query
  }
  const simpleQueryWithOrderAndLimitAndStartAfter = (
    table_name,
    search_name,
    num_of_days,
    no_per_page,
    last_visible
  ) => {
    const build_query = query(
      collection(fb.db, table_name),
      where(search_name, '>=', num_of_days),
      orderBy(search_name, 'desc'),
      limit(no_per_page),
      startAfter(last_visible)
    )

    return build_query
  }
  const multiSimpleQuery = (table_name, table_id, collection_name, search_name, num_of_days) => {
    const build_query = query(
      collection(fb.db, table_name, table_id, collection_name),
      where(search_name, '>=', num_of_days)
    )

    return build_query
  }
  const multiComplexQuery = (table_name, table_id, collection_name, search_name, start_date, end_date) => {
    const build_query = query(
      collection(fb.db, table_name, table_id, collection_name),
      where(search_name, '>=', start_date),
      where(search_name, '<=', end_date)
    )

    return build_query
  }
  const complexQuery = (table_name, search_name, start_date, end_date) => {
    const build_query = query(
      collection(fb.db, table_name),
      where(search_name, '>=', start_date),
      where(search_name, '<=', end_date),
      orderBy(search_name, 'desc')
    )
    return build_query
  }
  const complexQueryWithOrderAndLimit = (table_name, search_name, start_date, end_date, no_per_page) => {
    const build_query = query(
      collection(fb.db, table_name),
      where(search_name, '>=', start_date),
      where(search_name, '<=', end_date),
      orderBy(search_name, 'desc'),
      limit(no_per_page)
    )
    return build_query
  }
  const complexQueryWithOrderAndLimitAndStartAfter = (
    table_name,
    search_name,
    start_date,
    end_date,
    no_per_page,
    last_visible
  ) => {
    const build_query = query(
      collection(fb.db, table_name),
      where(search_name, '>=', start_date),
      where(search_name, '<=', end_date),
      orderBy(search_name, 'desc'),
      limit(no_per_page),
      startAfter(last_visible)
    )
    return build_query
  }
  const simpleGetDoc = (table_name, table_id) => {
    const build_query = doc(fb.db, table_name, table_id)
    return build_query
  }
  return {
    complexQuery,
    simpleQuery,
    simpleGetDoc,
    multiSimpleQuery,
    multiComplexQuery,
    complexQueryWithOrderAndLimit,
    simpleQueryWithOrderAndLimit,
    simpleQueryWithOrderAndLimitAndStartAfter,
    complexQueryWithOrderAndLimitAndStartAfter
  }
}
