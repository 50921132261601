import { getDocs, Timestamp, getDoc } from 'firebase/firestore'
import useDateFilter from '@/assets/lib/date_filter'
import useFirebaseQuery from '@/assets/lib/firebase_query'

export const namespaced = true

export const state = {
  user: {},
  dashboard_users_count: [],
  users: [],
  customers: [],
  users_loading: false,
  last_visible: null,
  previously_visible_list: [],
  no_more_items: false,
  no_per_page: 30,
  start_date: 0,
  end_date: 0
}

export const mutations = {
  SET_USER(state, payload) {
    state.user = payload
  },
  SET_USERS(state, payload) {
    state.users = payload
  },
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_CUSTOMERS(state, payload) {
    state.customers = payload
  },
  SET_DASHBOARD_USER_COUNT(state, payload) {
    state.dashboard_users_count = payload
  },
  SET_LAST_VISIBLE(state, payload) {
    state.last_visible = payload
  },
  SET_PREVIOUSLY_VISIBLE_LIST(state, payload) {
    state.previously_visible_list = payload
  },
  SET_NO_MORE_ITEMS(state, payload) {
    state.no_more_items = payload
  },
  SET_NO_PER_PAGE(state, payload) {
    state.no_per_page = payload
  },
  SET_FILTER_DATE(state, payload) {
    state.start_date = payload.start_date
    state.end_date = payload.end_date
  }
}

export const actions = {
  async singleUser({ commit }, payload) {
    const docRef = useFirebaseQuery().simpleGetDoc('users', payload)

    const docSnap = await getDoc(docRef)
    commit('SET_USER', {})
    if (docSnap.exists()) {
      const details = docSnap.data()
      details.id = docRef.id
      commit('SET_USER', details)
    } else {
      commit('SET_USER', {})
    }
  },
  async dashboardUsers({ commit }, payload) {
    const date_filter = useDateFilter().dateFilter(payload)
    const start_date = Timestamp.fromDate(new Date(date_filter.start_date))
    const end_date = Timestamp.fromDate(new Date(date_filter.end_date))
    const num_of_days = Timestamp.fromDate(new Date(date_filter.num_of_days))
    let businesses
    if (payload.period !== 'Between Dates') {
      businesses = useFirebaseQuery().multiSimpleQuery(
        'dashboard_summary',
        'users',
        'breakdown',
        'updated_at',
        num_of_days
      )
    } else {
      businesses = useFirebaseQuery().multiComplexQuery(
        'dashboard_summary',
        'users',
        'breakdown',
        'updated_at',
        start_date,
        end_date
      )
    }

    const querySnapshot = await getDocs(businesses)
    const container = []
    if (!querySnapshot.empty) {
      querySnapshot.forEach(userDoc => {
        const details = userDoc.data()
        details.id = userDoc.id
        container.push(details)
      })
      commit('SET_DASHBOARD_USER_COUNT', container)
    } else {
      commit('SET_DASHBOARD_USER_COUNT', container)
    }
  },

  async allUsers({ commit, dispatch }, payload) {
    commit('SET_USERS', [])
    const { start_date, end_date, num_of_days } = useDateFilter().dateFilter(payload)
    let userQuery
    if (payload.period !== 'Between Dates') {
      userQuery = useFirebaseQuery().simpleQueryWithOrderAndLimit('users', 'created_at', num_of_days, state.no_per_page)
    } else {
      userQuery = useFirebaseQuery().complexQueryWithOrderAndLimit(
        'users',
        'created_at',
        start_date,
        end_date,
        state.no_per_page
      )
    }
    const container = []
    const querySnapshot = await getDocs(userQuery)
    if (!querySnapshot.empty) {
      const last = querySnapshot.docs[querySnapshot.docs.length - 1]
      let previously_visible_list = []
      previously_visible_list.push(last)
      commit('SET_LAST_VISIBLE', last)
      commit('SET_PREVIOUSLY_VISIBLE_LIST', previously_visible_list)
      querySnapshot.forEach(userDocs => {
        const transObj = userDocs.data()
        transObj.id = userDocs.id
        container.push(transObj)
      })
      dispatch('getBusinessAndSubscription', container)
    }
  },

  async nextUsers({ commit, dispatch }, payload) {
    commit('SET_USERS', [])
    const { start_date, end_date, num_of_days } = useDateFilter().dateFilter(payload)
    let userQuery
    if (payload.period !== 'Between Dates') {
      userQuery = useFirebaseQuery().simpleQueryWithOrderAndLimitAndStartAfter(
        'users',
        'created_at',
        num_of_days,
        state.no_per_page,
        state.last_visible
      )
    } else {
      userQuery = useFirebaseQuery().complexQueryWithOrderAndLimitAndStartAfter(
        'users',
        'created_at',
        start_date,
        end_date,
        state.no_per_page,
        state.last_visible
      )
    }
    // const userQuery = query(
    //   collection(fb.db, 'users'),
    //   where('created_at', '>=', state.start_date),
    //   where('created_at', '<=', state.end_date),
    //   orderBy('created_at', 'desc'),
    //   limit(state.no_per_page),
    //   startAfter(state.last_visible)
    // )
    const querySnapshot = await getDocs(userQuery)
    const container = []
    if (!querySnapshot.empty) {
      const last = querySnapshot.docs[querySnapshot.docs.length - 1]
      let previously_visible_list = state.previously_visible_list
      previously_visible_list.push(last)

      commit('SET_LAST_VISIBLE', last)
      commit('SET_PREVIOUSLY_VISIBLE_LIST', previously_visible_list)

      querySnapshot.forEach(userDocs => {
        const transObj = userDocs.data()
        transObj.id = userDocs.id
        container.push(transObj)
      })
      dispatch('getBusinessAndSubscription', container)
    } else {
      commit('SET_NO_MORE_ITEMS', true)
    }
  },

  async prevUsers({ commit, dispatch }, payload) {
    const previous = state.previously_visible_list
    const previousIndex = previous.length - 1
    commit('SET_NO_MORE_ITEMS', false)
    commit('SET_USERS', [])
    const { start_date, end_date, num_of_days } = useDateFilter().dateFilter(payload)
    let first

    if (previousIndex - 2 >= 0) {
      if (payload.period !== 'Between Dates') {
        first = useFirebaseQuery().simpleQueryWithOrderAndLimitAndStartAfter(
          'users',
          'created_at',
          num_of_days,
          state.no_per_page,
          previous[previousIndex - 2]
        )
      } else {
        first = useFirebaseQuery().complexQueryWithOrderAndLimitAndStartAfter(
          'users',
          'created_at',
          start_date,
          end_date,
          state.no_per_page,
          previous[previousIndex - 2]
        )
      }
      // const first = query(
      //   collection(fb.db, 'users'),
      //   where('created_at', '>=', state.start_date),
      //   where('created_at', '<=', state.end_date),
      //   orderBy('created_at', 'desc'),
      //   limit(state.no_per_page),
      //   startAfter(previous[previousIndex - 2])
      // )
      const querySnapshot = await getDocs(first)
      const container = []
      if (!querySnapshot.empty) {
        const last = previous[previousIndex - 1]
        previous.pop()

        commit('SET_LAST_VISIBLE', last)
        commit('SET_PREVIOUSLY_VISIBLE_LIST', previous)

        querySnapshot.forEach(userDocs => {
          const transObj = userDocs.data()
          transObj.id = userDocs.id
          container.push(transObj)
        })
        dispatch('getBusinessAndSubscription', container)
      } else {
        commit('SET_USERS', [])
      }
    } else {
      previous.pop()
      commit('SET_LAST_VISIBLE', null)
      dispatch('allUsers', payload)
    }
  },

  async getBusinessAndSubscription({ commit }, payload) {
    for (const user of payload) {
      if (user.default_business_uid !== undefined) {
        const docRef = useFirebaseQuery().simpleGetDoc('businesses', user.default_business_uid)
        // const docRef = doc(fb.db, 'businesses', user.default_business_uid)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
          const details = docSnap.data()
          details.id = docRef.id
          user.businesses = details

          const subQuery = useFirebaseQuery().simpleGetDoc('subscriptions', user.default_business_uid)
          // const subQuery = doc(fb.db, 'subscriptions', user.default_business_uid)
          const subSnap = await getDoc(subQuery)
          if (subSnap.exists()) {
            const subDetails = subSnap.data()
            subDetails.id = subQuery.id
            if (subDetails.current !== undefined) {
              user.subscriptions = subDetails
            }
          }
        }
      }
    }
    commit('SET_USERS', payload)
  }
}

export const getters = {
  searchName: state => email => {
    var customers = []
    email = email.toUpperCase()
    state.customers.forEach(customer => {
      var customerEmail = customer.email.toUpperCase()
      if (customerEmail.includes(email)) {
        customers.push(customer)
      }
    })
    return customers
  }
}
