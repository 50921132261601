import fb from '@/services/firebase'
import { collection, query, getDocs, where, Timestamp, updateDoc, doc, getDoc } from 'firebase/firestore'

export const namespaced = true

export const state = {
  farm_loading: false,
  farms: []
}

export const mutations = {
  SET_FARM_LOADER(state, payload) {
    state.farm_loading = payload
  },
  SET_FARMS(state, payload) {
    state.farms = payload
  },
  UPDATE_FARMS(state, payload) {
    state.farms.forEach(item => {
      const businessFound = item.farm_activities.findIndex(
        eachBusiness => eachBusiness.business_id === payload.business_id
      )
      if (businessFound !== -1) {
        item.farm_activities[businessFound].business_name = payload.business_name
        item.farm_activities[businessFound].owner_id = payload.owner_id
        item.new_item = true
      }
    })
  }
}

export const actions = {
  async allFarms({ commit, dispatch }, payload) {
    const { period, start_date, end_date, number_of_period } = payload
    commit('SET_FARM_LOADER', false)
    var d = new Date()
    var year = d.getFullYear()
    var month = d.getMonth()
    var day = d.getDate()
    var hour = d.getHours()
    var minutes = d.getMinutes()
    if (period === 'Today') {
      var num_of_days = new Date(year, month, day, 0, minutes).getTime()
    } else if (period === 'All time') {
      num_of_days = new Date(0, 0, 0, 0, 0).getTime()
    } else if (period === 'Days') {
      num_of_days = new Date(year, month, day - number_of_period, hour, minutes).getTime()
    } else if (period === 'Months') {
      num_of_days = new Date(year, month - number_of_period, day, hour, minutes).getTime()
    }

    if (period !== 'Between Dates') {
      dispatch('simpleAllFarms', { num_of_days: num_of_days })
    } else {
      dispatch('complexDateRangeForAllFarms', {
        start_date: start_date,
        end_date: end_date
      })
    }
  },
  async updateDashboardSummary({ state }) {
    state.farms.forEach(item => {
      if (item.new_item != undefined) {
        updateDoc(doc(fb.db, 'dashboard_summary', 'farms', 'breakdown', item.id), {
          farm_activities: item.farm_activities
        })
      }
    })
  },
  async getBusinessDetails({ commit }, payload) {
    const business_details = await getDoc(doc(fb.db, 'businesses', payload.business_id))
    commit('UPDATE_FARMS', {
      business_id: payload.business_id,
      business_name: business_details.data().business_name,
      owner_id: business_details.data().owner_id
    })
  },

  async simpleAllFarms({ commit, dispatch }, payload) {
    const { num_of_days } = payload
    commit('SET_FARMS', [])
    const new_date = Timestamp.fromDate(new Date(num_of_days))
    const sales = query(
      collection(fb.db, 'dashboard_summary', 'farms', 'breakdown'),
      where('updated_at', '>=', new_date)
    )
    const querySnapshot = await getDocs(sales)
    const container = []
    if (!querySnapshot.empty) {
      querySnapshot.forEach(snapDoc => {
        const details = snapDoc.data()
        details.id = snapDoc.id
        container.push(details)
        for (let index = 0; index < snapDoc.data().farm_activities.length; index++) {
          const business = snapDoc.data().farm_activities[index]
          if (business.business_name === undefined) {
            dispatch('getBusinessDetails', {
              business_id: business.business_id
            })
          }
        }
      })
      commit('SET_FARMS', container)
    } else {
      commit('SET_FARMS', container)
    }
  },

  async complexDateRangeForAllFarms({ commit, dispatch }, payload) {
    const { start_date, end_date } = payload
    commit('SET_FARMS', [])
    const new_start_date = Timestamp.fromDate(new Date(start_date))
    const new_end_date = Timestamp.fromDate(new Date(end_date))
    const sales = query(
      collection(fb.db, 'dashboard_summary', 'farms', 'breakdown'),
      where('updated_at', '>=', new_start_date),
      where('updated_at', '<=', new_end_date)
    )
    const querySnapshot = await getDocs(sales)
    const container = []
    if (!querySnapshot.empty) {
      querySnapshot.forEach(snapDoc => {
        const details = snapDoc.data()
        details.id = snapDoc.id
        container.push(details)
        for (let index = 0; index < snapDoc.data().farm_activities.length; index++) {
          const business = snapDoc.data().farm_activities[index]
          if (business.business_name === undefined) {
            dispatch('getBusinessDetails', {
              business_id: business.business_id
            })
          }
        }
      })
      commit('SET_FARMS', container)
    } else {
      commit('SET_FARMS', container)
    }
  }
}
