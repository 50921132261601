import Vue from 'vue'
import Vuex from 'vuex'
import fb from '@/services/firebase'
import Swal from 'sweetalert2'
import router from '../router'
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { collection, doc, onSnapshot } from 'firebase/firestore'

import * as middleware from './modules/middleware'
import * as admins from './modules/admins'
import * as users from './modules/users'
import * as shops from './modules/shops'
import * as sales from './modules/sales'
import * as farm from './modules/farm'
import * as businesses from './modules/businesses'
// import * as farm_sales from './modules/farm_sales'
// import * as shops_sales from './modules/shops_sales'
import * as promo_code from './modules/promo_code'
import * as expenses from './modules/expenses'
import * as settings from './modules/settings'
import * as customers from './modules/customers'
import * as suppliers from './modules/suppliers'
import * as referrals from './modules/referrals'
import * as raw_materials from './modules/raw_materials'
import * as not_active from './modules/not_active'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {},
    loading: false,
    new_app_version: false,
    current_version: null
  },
  mutations: {
    SET_USER(state, payload) {
      state.user = payload
    },
    SET_LOADING(state, payload) {
      state.loading = payload
    },
    SET_APP_VERSION(state, payload) {
      state.new_app_version = payload
    },
    SET_CURRENT_VERSION(state, payload) {
      state.current_version = payload
    }
  },
  actions: {
    setUser({ commit }, user) {
      var details = {
        email: user.email,
        first_name: '',
        last_name: '',
        role: 0,
        uid: user.uid
      }

      onSnapshot(doc(fb.db, 'admins', user.uid), userDoc => {
        details.first_name = userDoc.data().first_name
        details.last_name = userDoc.data().last_name
        details.role = userDoc.data().role
        commit('SET_USER', details)
      })
    },
    saveToAdminTable({ commit }, userDetails) {
      commit('SET_LOADING', true)
      var uid = userDetails.uid
      delete userDetails.uid
      fb.db
        .collection('admins_notapproved')
        .doc(uid)
        .set(userDetails)
        .then(() => {
          commit('SET_LOADING', false)
          router.push({
            name: 'WaitingRoom'
          })
        })
    },
    login({ commit, dispatch }, user) {
      commit('SET_LOADING', true)

      const auth = getAuth(fb.app)
      signInWithEmailAndPassword(auth, user.email, user.password)
        .then(userCredential => {
          commit('SET_LOADING', false)
          // Signed in
          var user = userCredential.user
          dispatch('setUser', user)
        })
        .catch(error => {
          commit('SET_LOADING', false)
          var errorCode = error.code
          var errorMessage = error.message
          Swal.fire(errorCode, errorMessage, 'error')
        })
    },
    logout({ commit }) {
      commit('SET_LOADING', true)
      const auth = getAuth()
      signOut(auth)
        .then(() => {
          // Sign-out successful.
          commit('SET_LOADING', false)
          Swal.fire('', 'Sign-out successful.', 'info')
        })
        .catch(error => {
          // An error happened.
          commit('SET_LOADING', false)
          console.log(error)
          Swal.fire('', 'Oops something went wrong', 'error')
        })
    },
    getAndSetUser({ dispatch }) {
      const auth = getAuth(fb.app)
      onAuthStateChanged(auth, user => {
        if (user) {
          dispatch('setUser', user)
        }
      })
    },
    forgotPassword({ commit }, email) {
      commit('SET_LOADING', true)
      fb.auth
        .sendPasswordResetEmail(email)
        .then(() => {
          Swal.fire('Success!', 'Reset link has been sent to ' + email, 'success')
        })
        .catch(error => {
          var errorCode = error.code
          var errorMessage = error.message
          Swal.fire(errorCode, errorMessage, 'success')
          commit('SET_LOADING', false)
          return true
        })
    },
    appVersion({ commit, dispatch }) {
      onSnapshot(collection(fb.db, 'admin_app_version'), docs => {
        var version = docs.docs[0].data().version
        var length = version.length
        var currentVersion = version[length - 1]
        commit('SET_CURRENT_VERSION', currentVersion)
        if (localStorage.getItem('admin_app_version') == null) {
          dispatch('addAppVersionToLocalStorage')
        }
        if (
          localStorage.getItem('admin_app_version') != currentVersion &&
          localStorage.getItem('admin_app_version') != null
        ) {
          commit('SET_APP_VERSION', true)
        }
      })
    },
    addAppVersionToLocalStorage({ getters }) {
      var currentVersion = getters.getCurrentVersion
      localStorage.setItem('admin_app_version', currentVersion)
      indexedDB.deleteDatabase('firebaseLocalStorageDb')
      window.location.reload(true)
      // window.history.forward(1)
    }
  },
  getters: {
    getCurrentVersion: state => {
      return state.current_version
    }
  },
  modules: {
    users,
    middleware,
    admins,
    sales,
    shops,
    farm,
    expenses,
    settings,
    referrals,
    customers,
    suppliers,
    businesses,
    // farm_sales,
    promo_code,
    // shops_sales,
    raw_materials,
    not_active
  }
})
