import fb from '@/services/firebase'

export const namespaced = true

export const state = {
  promo_code: [],
  loading: false
}

export const mutations = {
  SET_PROMO_CODE(state, payload) {
    state.promo_code = payload
  },
  SET_LOADING(state, payload) {
    state.loading = payload
  }
}

export const actions = {
  promoCode({ commit }) {
    fb.db.collection('promo_code').onSnapshot(docs => {
      var code = []
      docs.forEach(doc => {
        var details = doc.data()
        details.doc_id = doc.id
        code.push(details)
      })
      commit('SET_PROMO_CODE', code)
    })
  },
  addToPromoCode({ commit }, payload) {
    commit('SET_LOADING', true)
    fb.db
      .collection('promo_code')
      .add(payload)
      .then(() => {
        return true
      })
  },
  editPromoCode({ commit }, payload) {
    commit('SET_LOADING', true)
    var doc_id = payload.doc_id
    delete payload.doc_id
    fb.db
      .collection('promo_code')
      .doc(doc_id)
      .update(payload)
      .then(() => {
        return true
      })
  }
}

export const getters = {}
