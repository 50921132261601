import { getDocs, getDoc, Timestamp } from 'firebase/firestore'
import useDateFilter from '@/assets/lib/date_filter'
import useFirebaseQuery from '@/assets/lib/firebase_query'

export const namespaced = true

export const state = {
  premuim_businesses: [],
  all_businesses: [],
  finished_filtering: 0,
  business_loading: false,
  dashboard_businesses: [],
  business: {},
  start_date: 0,
  end_date: 0
}

export const mutations = {
  SET_FINISHED(state, payload) {
    state.finished_filtering = payload
  },
  SET_BUSINESS(state, payload) {
    state.business = payload
  },
  SET_PREMUIM_SUBSCRIBED_BUSINESSES(state, payload) {
    state.premuim_businesses = payload
  },
  SET_BUSINESSES_LOADING(state, payload) {
    state.business_loading = payload
  },
  SET_ALL_BUSINESSES(state, payload) {
    state.all_businesses = payload
  },
  SET_DASHBOARD_BUSINESSES(state, payload) {
    state.dashboard_businesses = payload
  },
  SET_FILTER_DATE(state, payload) {
    state.start_date = payload.start_date
    state.end_date = payload.end_date
  }
}

export const actions = {
  async businesses({ commit }, payload) {
    commit('SET_BUSINESSES_LOADING', true)
    const { start_date, end_date, num_of_days } = useDateFilter().dateFilter(payload)

    let businesses
    if (payload.period !== 'Between Dates') {
      businesses = useFirebaseQuery().simpleQuery('businesses', 'updated_at', num_of_days)
    } else {
      businesses = useFirebaseQuery().complexQuery('businesses', 'updated_at', start_date, end_date)
    }
    const querySnapshot = await getDocs(businesses)
    const container = []
    commit('SET_FINISHED', 0)
    if (!querySnapshot.empty) {
      querySnapshot.forEach(async businessDoc => {
        const details = businessDoc.data()
        details.id = businessDoc.id

        const docRef = useFirebaseQuery().simpleGetDoc('subscriptions', details.id)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
          details.plan_id = docSnap.data().current.plan_id
          details.expires_at = docSnap.data().current.expires_at
        } else {
          details.plan_id = 'incompleted'
        }

        const ownerRef = useFirebaseQuery().simpleGetDoc('users', details.owner_id)
        const ownerSnap = await getDoc(ownerRef)
        if (ownerSnap.exists()) {
          details.owner_first_name = ownerSnap.data().first_name
          details.owner_last_name = ownerSnap.data().last_name
        }
        container.push(details)
      })
      commit('SET_FINISHED', querySnapshot.size)
      commit('SET_ALL_BUSINESSES', container)
    } else {
      commit('SET_ALL_BUSINESSES', container)
    }
  },
  async getSubscriptionAndOwner({ commit }, payload) {
    for (const user of payload) {
      if (user.default_business_uid !== undefined) {
        const docRef = useFirebaseQuery().simpleGetDoc('subscriptions', user.default_business_uid)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
          const details = docSnap.data()
          details.id = docRef.id
          user.businesses = details

          const subQuery = useFirebaseQuery().simpleGetDoc('subscriptions', user.default_business_uid)
          const subSnap = await getDoc(subQuery)
          if (subSnap.exists()) {
            const subDetails = subSnap.data()
            subDetails.id = subQuery.id
            if (subDetails.current !== undefined) {
              user.subscriptions = subDetails
            }
          }
        }
      }
    }
    commit('SET_USERS', payload)
  },
  async dashboardBusiness({ commit }, payload) {
    commit('SET_BUSINESSES_LOADING', true)
    const date_filter = useDateFilter().dateFilter(payload)
    const start_date = Timestamp.fromDate(new Date(date_filter.start_date))
    const end_date = Timestamp.fromDate(new Date(date_filter.end_date))
    const num_of_days = Timestamp.fromDate(new Date(date_filter.num_of_days))

    let businesses
    if (payload.period !== 'Between Dates') {
      businesses = useFirebaseQuery().multiSimpleQuery(
        'dashboard_summary',
        'businesses',
        'breakdown',
        'updated_at',
        num_of_days
      )
    } else {
      businesses = useFirebaseQuery().multiComplexQuery(
        'dashboard_summary',
        'businesses',
        'breakdown',
        'updated_at',
        start_date,
        end_date
      )
    }

    const querySnapshot = await getDocs(businesses)
    const container = []
    commit('SET_FINISHED', 0)
    if (!querySnapshot.empty) {
      querySnapshot.forEach(businessDoc => {
        const details = businessDoc.data()
        details.id = businessDoc.id
        container.push(details)
      })
      commit('SET_FINISHED', querySnapshot.size)
      commit('SET_DASHBOARD_BUSINESSES', container)
    } else {
      commit('SET_DASHBOARD_BUSINESSES', container)
    }
  },
  async getBusiness({ commit }, payload) {
    const docRef = useFirebaseQuery().simpleGetDoc('businesses', payload)
    const docSnap = await getDoc(docRef)
    commit('SET_BUSINESS', {})
    if (docSnap.exists()) {
      const details = docSnap.data()
      details.id = docRef.id
      commit('SET_BUSINESS', details)
    } else {
      commit('SET_BUSINESS', {})
    }
  }
}

export const getters = {}
