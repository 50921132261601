import fb from '@/services/firebase'
import { Timestamp, getDocs, getDoc, doc, updateDoc } from 'firebase/firestore'
import useDateFilter from '@/assets/lib/date_filter'
import useFirebaseQuery from '@/assets/lib/firebase_query'

export const namespaced = true

export const state = {
  purchases: [],
  purchases_count: 0,
  farm_shops: [],
  purchases_loading: false,
  farm_shops_loading: false
}

export const mutations = {
  SET_PURCHASES(state, payload) {
    state.purchases = payload
  },
  UPDATE_PURCHASES(state, payload) {
    state.purchases.forEach(item => {
      const businessFound = item.businesses.findIndex(eachBusiness => eachBusiness.business_id === payload.business_id)
      if (businessFound !== -1) {
        item.businesses[businessFound].business_name = payload.business_name
        item.businesses[businessFound].owner_id = payload.owner_id
        item.new_item = true
      }
    })
  },
  SET_PURCHASES_LOADING(state, payload) {
    state.purchases_loading = payload
  },
  SET_PURCHASES_COUNT(state, payload) {
    state.purchases_count = payload
  },
  SET_FARM_SHOPS(state, payload) {
    state.farm_shops = payload
  },
  UPDATE_FARM_SHOPS(state, payload) {
    state.farm_shops.forEach(item => {
      const businessFound = item.businesses.findIndex(eachBusiness => eachBusiness.business_id === payload.business_id)
      if (businessFound !== -1) {
        item.businesses[businessFound].business_name = payload.business_name
        item.businesses[businessFound].owner_id = payload.owner_id
        item.new_item = true
      }
    })
  },
  SET_FARM_SHOPS_LOADING(state, payload) {
    state.farm_shops_loading = payload
  }
}

export const actions = {
  async allShopsInventory({ commit, dispatch }, payload) {
    const date_filter = useDateFilter().dateFilter(payload)
    const start_date = Timestamp.fromDate(new Date(date_filter.start_date))
    const end_date = Timestamp.fromDate(new Date(date_filter.end_date))
    const num_of_days = Timestamp.fromDate(new Date(date_filter.num_of_days))

    let shops
    if (payload.period !== 'Between Dates') {
      shops = useFirebaseQuery().multiSimpleQuery(
        'dashboard_summary',
        'purchases',
        'breakdown',
        'updated_at',
        num_of_days
      )
    } else {
      shops = useFirebaseQuery().multiComplexQuery(
        'dashboard_summary',
        'purchases',
        'breakdown',
        'updated_at',
        start_date,
        end_date
      )
    }

    // const sales = query(
    //   collection(fb.db, 'dashboard_summary', 'purchases', 'breakdown'),
    //   where('updated_at', '>=', new_date)
    // )
    const querySnapshot = await getDocs(shops)
    const container = []
    if (!querySnapshot.empty) {
      querySnapshot.forEach(snapDoc => {
        const details = snapDoc.data()
        details.id = snapDoc.id
        container.push(details)
        for (let index = 0; index < snapDoc.data().businesses.length; index++) {
          const business = snapDoc.data().businesses[index]
          if (business.business_name === undefined) {
            dispatch('getBusinessDetails', {
              business_id: business.business_id,
              type: 'purchases'
            })
          }
        }
      })
      commit('SET_PURCHASES', container)
    } else {
      commit('SET_PURCHASES', container)
    }
  },
  async updateDashboardSummary({ state }, type) {
    if (type === 'purchases') {
      state.purchases.forEach(item => {
        if (item.new_item != undefined) {
          updateDoc(doc(fb.db, 'dashboard_summary', 'purchases', 'breakdown', item.id), {
            businesses: item.businesses
          })
        }
      })
    } else if (type === 'farm_shops') {
      state.farm_shops.forEach(item => {
        if (item.new_item != undefined) {
          updateDoc(doc(fb.db, 'dashboard_summary', 'farm_shops', 'breakdown', item.id), {
            businesses: item.businesses
          })
        }
      })
    }
  },
  async getBusinessDetails({ commit }, payload) {
    const business_details = await getDoc(doc(fb.db, 'businesses', payload.business_id))
    if (payload.type === 'purchases') {
      commit('UPDATE_PURCHASES', {
        business_id: payload.business_id,
        business_name: business_details.data().business_name,
        owner_id: business_details.data().owner_id
      })
    } else if (payload.type === 'farm_shops') {
      commit('UPDATE_FARM_SHOPS', {
        business_id: payload.business_id,
        business_name: business_details.data().business_name,
        owner_id: business_details.data().owner_id
      })
    }
  },

  async allFarmShopsInventory({ commit, dispatch }, payload) {
    const date_filter = useDateFilter().dateFilter(payload)
    const start_date = Timestamp.fromDate(new Date(date_filter.start_date))
    const end_date = Timestamp.fromDate(new Date(date_filter.end_date))
    const num_of_days = Timestamp.fromDate(new Date(date_filter.num_of_days))

    let farm_shops
    if (payload.period !== 'Between Dates') {
      farm_shops = useFirebaseQuery().multiSimpleQuery(
        'dashboard_summary',
        'farm_shops',
        'breakdown',
        'updated_at',
        num_of_days
      )
    } else {
      farm_shops = useFirebaseQuery().multiComplexQuery(
        'dashboard_summary',
        'farm_shops',
        'breakdown',
        'updated_at',
        start_date,
        end_date
      )
    }

    // const sales = query(
    //   collection(fb.db, 'dashboard_summary', 'farm_shops', 'breakdown'),
    //   where('updated_at', '>=', new_date)
    // )
    const querySnapshot = await getDocs(farm_shops)
    const container = []
    if (!querySnapshot.empty) {
      querySnapshot.forEach(snapDoc => {
        const details = snapDoc.data()
        details.id = snapDoc.id
        container.push(details)
        for (let index = 0; index < snapDoc.data().businesses.length; index++) {
          const business = snapDoc.data().businesses[index]
          if (business.business_name === undefined) {
            dispatch('getBusinessDetails', {
              business_id: business.business_id,
              type: 'farm_shops'
            })
          }
        }
      })
      commit('SET_FARM_SHOPS', container)
    } else {
      commit('SET_FARM_SHOPS', container)
    }
  }
}
