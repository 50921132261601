import fb from '@/services/firebase'
import { Timestamp, getDocs, updateDoc, doc, getDoc } from 'firebase/firestore'
import useDateFilter from '@/assets/lib/date_filter'
import useFirebaseQuery from '@/assets/lib/firebase_query'

export const namespaced = true

export const state = {
  expenses_inventory: [],
  expenses_loading: false
}

export const mutations = {
  SET_EXPENSES_INVENTORY(state, payload) {
    state.expenses_inventory = payload
  },
  SET_EXPENSES_LOADING(state, payload) {
    state.expenses_loading = payload
  },
  UPDATE_EXPENSES_INVENTORY(state, payload) {
    state.expenses_inventory.forEach(item => {
      const businessFound = item.businesses.findIndex(eachBusiness => eachBusiness.business_id === payload.business_id)
      if (businessFound !== -1) {
        item.businesses[businessFound].business_name = payload.business_name
        item.businesses[businessFound].owner_id = payload.owner_id
        item.new_item = true
      }
    })
  }
}

export const actions = {
  async allexpensesInventory({ commit, dispatch }, payload) {
    const date_filter = useDateFilter().dateFilter(payload)
    const start_date = Timestamp.fromDate(new Date(date_filter.start_date))
    const end_date = Timestamp.fromDate(new Date(date_filter.end_date))
    const num_of_days = Timestamp.fromDate(new Date(date_filter.num_of_days))

    let expenses
    if (payload.period !== 'Between Dates') {
      expenses = useFirebaseQuery().multiSimpleQuery(
        'dashboard_summary',
        'expenses',
        'breakdown',
        'updated_at',
        num_of_days
      )
    } else {
      expenses = useFirebaseQuery().multiComplexQuery(
        'dashboard_summary',
        'expenses',
        'breakdown',
        'updated_at',
        start_date,
        end_date
      )
    }

    commit('SET_EXPENSES_INVENTORY', [])
    // const sales = query(
    //   collection(fb.db, 'dashboard_summary', 'expenses', 'breakdown'),
    //   where('updated_at', '>=', new_date)
    // )
    const querySnapshot = await getDocs(expenses)
    const container = []
    if (!querySnapshot.empty) {
      querySnapshot.forEach(snapDoc => {
        const details = snapDoc.data()
        details.id = snapDoc.id
        container.push(details)
        for (let index = 0; index < snapDoc.data().businesses.length; index++) {
          const business = snapDoc.data().businesses[index]
          if (business.business_name === undefined) {
            dispatch('getBusinessDetails', {
              business_id: business.business_id
            })
          }
        }
      })
      commit('SET_EXPENSES_INVENTORY', container)
    } else {
      commit('SET_EXPENSES_INVENTORY', container)
    }
  },
  async updateDashboardSummary({ state }) {
    state.expenses_inventory.forEach(item => {
      if (item.new_item != undefined) {
        updateDoc(doc(fb.db, 'dashboard_summary', 'expenses', 'breakdown', item.id), {
          businesses: item.businesses
        })
      }
    })
  },
  async getBusinessDetails({ commit }, payload) {
    const business_details = await getDoc(doc(fb.db, 'businesses', payload.business_id))
    commit('UPDATE_EXPENSES_INVENTORY', {
      business_id: payload.business_id,
      business_name: business_details.data().business_name,
      owner_id: business_details.data().owner_id
    })
  }
}
